// @flow
import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';

// components
import AccountLayout from '../../layouts/Authentication/AccountLayout';

// images
import logoutIcon from '../../assets/images/logout-icon.svg';

// Contexts
import { AccountContext } from '../../contexts/Accounts';

/* bottom link */
const BottomLink = () => {
  return (
    <Row className="mt-3">
      <Col className="text-center">
        <p className="text-muted">
          Back to{' '}
          <Link to="/login" className="text-muted ms-1">
            <b>Log In</b>
          </Link>
        </p>
      </Col>
    </Row>
  );
};

const Logout = () => {
  const { logout } = useContext(AccountContext);
  const dispatch = useDispatch();

  useEffect(() => {
    logout(false);
  }, [dispatch]);

  return (
    <>
      <AccountLayout bottomLinks={<BottomLink />}>
        <div className="text-center w-75 m-auto">
          <h4 className="text-dark-50 text-center mt-0 fw-bold">See You Again !</h4>
          <p className="text-muted mb-4">You are now successfully sign out.</p>

          <div className="logout-icon m-auto">
            <img src={logoutIcon} alt="" />
          </div>
        </div>
      </AccountLayout>
    </>
  );
};

export default Logout;
