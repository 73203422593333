import React, { useContext, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';

import { AccountContext } from '../../contexts/Accounts';

import AuthenticationInput from '../../components/AuthenticationInput';
import AuthenticationButton from '../../components/AuthenticationButton';

import AuthenticationLayout from '../../layouts/Authentication';

const NewPassword = () => {
  const history = useHistory();
  const location = useLocation();
  const { handleNewPassword } = useContext(AccountContext);
  const [submitError, setSubmitError] = useState();

  const { state } = location;

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(6, 'Must be 6 characters or more')
        .required('Required'),
      confirmPassword: Yup.string()
        .min(6, 'Must be 6 characters or more')
        .required('Required')
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
    }),
    onSubmit: (values) => {
      if (values.password === values.confirmPassword) {
        handleNewPassword(values.password, { ...state })
          .then(() => {
            const urlParams = new URLSearchParams(location.search.replace('?', ''));
            const from = urlParams.get('from');
            history.push(from || '/');
          })
          .catch((err) => {
            setSubmitError(err.message);
          });
      }
    }
  });

  return (
    <AuthenticationLayout>
      <div className="NewPassword">
        <div className="first">Please create a new password.</div>
        <form id="newpassword-form" onSubmit={formik.handleSubmit}>
          <AuthenticationInput
            name="password"
            placeholder="New password"
            type="password"
            onChange={formik.handleChange}
            value={formik.values.password}
            error={formik.errors.password}
          />
          <AuthenticationInput
            name="confirmPassword"
            title="Confirm password"
            placeholder="Confirm new password"
            type="password"
            onChange={formik.handleChange}
            value={formik.values.confirmPassword}
            error={formik.errors.confirmPassword}
          />
          <div className="second">
            <div />
            <AuthenticationButton
              disabled={!(formik.isValid && formik.dirty)}
              text="confirm"
            />
          </div>
          <i className="form-error">{submitError}</i>
        </form>
      </div>
    </AuthenticationLayout>
  );
};

export default NewPassword;
