/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

const AuthenticationButton = ({ text, disabled }) => (
  <button type="submit" className="AuthenticationSubmitButton" disabled={disabled}>
    {text}
  </button>
);

export default AuthenticationButton;
