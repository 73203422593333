/* eslint-disable react/no-children-prop */
import React, { memo } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
// import DashboardLayout from '../../layouts/Dashboard';
import Vertical from '../../layouts/Dashboard/Vertical';

const MapAllowedRoutes = ({ status, routes }) => (
  <Vertical status={status} routes={routes}>
    <Switch>
      <Route
        exact
        key="default-link"
        path="/"
        render={() => (
          <Redirect
            to={
              routes.filter((route) => !route.isTitle).length &&
              routes.filter((route) => !route.isTitle)[0].path
            }
          />
        )}
      />
      {routes
        .filter((route) => !route.isTitle)
        .map((route) => {
          const { path, component: Component, children, exact, ...rest } = route;
          return (
            <Route {...rest} key={path || 'default'} path={path} exact={exact}>
              <Component children={children} status={status} />
            </Route>
          );
        })}
    </Switch>
  </Vertical>
);

export default memo(MapAllowedRoutes);
