/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import AccountLayout from '../../layouts/Authentication/AccountLayout';
import mailSent from '../../assets/images/mail_sent.svg';

const RegistrationComplete = () => (
  <AccountLayout>
    <div className="text-center m-auto">
      <img src={mailSent} alt="mail sent" height="64" />
      <h4 className="text-dark-50 text-center mt-4 fw-bold">
        Hang on, One More Thing!
      </h4>
      <p className="text-muted mb-4">
        <br />
        <strong>
          You must now confirm your registration at TURTLE.
          <br />
          Don&apos;t worry, It&apos;s really easy!
        </strong>
        <br />
        <br />
        <p className="text-start">
          <ol>
            <li>Check your email</li>
            <li>Click the Confirmation Button in the email we have sent you</li>
            <li>Done! 🎉</li>
          </ol>
        </p>
        <br />
        If you can&apos;t find the email, please check the spam folder.
        <br />
        <br />
        <strong>Welcome on board!</strong>
        <br />
        <br />
        Best regards,
        <br />
        <br />
        <strong>Your TURTLE Team</strong>
      </p>
    </div>
  </AccountLayout>
);

export default RegistrationComplete;
