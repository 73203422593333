/* eslint-disable arrow-body-style */
/* eslint-disable no-alert */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-console */
/* eslint-disable comma-dangle */
import React, { createContext, useMemo } from 'react';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';

import Pool from '../core/utils/UserPool';

const AccountContext = createContext();

const Account = (props) => {
  const user = {};
  let cognitoUser;

  const getSession = () => {
    return new Promise((resolve, reject) => {
      cognitoUser = Pool.getCurrentUser();
      if (cognitoUser != null) {
        cognitoUser.getSession((err, session) => {
          if (err) {
            reject(err);
            return;
          }
          user.session = session;
          cognitoUser.getUserAttributes((error, result) => {
            if (error) {
              console.log(err);
              reject(err);
              return;
            }
            user.attributes = result;
            resolve(result);
          });
        });
      } else {
        reject('No user');
      }
    });
  };

  const authenticate = (Username, Password) =>
    new Promise((resolve, reject) => {
      const cognitoUsername = Username.trim().toLowerCase();
      cognitoUser = new CognitoUser({
        Username: cognitoUsername,
        Pool
      });

      const authDetails = new AuthenticationDetails({
        Username: cognitoUsername,
        Password
      });

      cognitoUser.authenticateUser(authDetails, {
        onSuccess: (data) => {
          resolve(data);
        },
        onFailure: (err) => {
          console.log('onFailure', err);
          reject(err);
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          resolve({
            userAttributes,
            requiredAttributes,
            newPasswordRequired: true
          });
        },
        mfaRequired: function (codeDeliveryDetails) {
          const mfaCode = window.prompt('Please enter the code you received: ', '');
          cognitoUser.sendMFACode(mfaCode, this);
        }
      });
    });

  const handleForgotPassword = (Username) =>
    new Promise((resolve, reject) => {
      const cognitoUsername = Username.trim().toLowerCase();
      cognitoUser = new CognitoUser({
        Username: cognitoUsername,
        Pool
      });

      cognitoUser.forgotPassword({
        onSuccess: (data) => {
          console.log('onSuccess', data);
          resolve(data);
        },
        onFailure: (err) => {
          console.log('onFailure', err);
          reject(err);
        },
        undefined
      });
    });

  const handleConfirmPassword = (Username, verificationCode, newPassword) =>
    new Promise((resolve, reject) => {
      const cognitoUsername = Username.trim().toLowerCase();
      cognitoUser = new CognitoUser({
        Username: cognitoUsername,
        Pool
      });

      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onSuccess: (data) => {
          console.log('onSuccess', data);
          resolve(data);
        },
        onFailure: (err) => {
          console.log('onFailure', err);
          reject(err);
        }
      });
    });

  const logout = () => {
    cognitoUser = Pool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.signOut();
      window.location.reload(false);
    }
  };

  const handleNewPassword = (newPassword, dataObj) => {
    const { requiredAttributes } = dataObj;
    return new Promise((resolve, reject) => {
      cognitoUser.completeNewPasswordChallenge(newPassword, requiredAttributes, {
        onSuccess: (data) => {
          resolve(data);
        },
        onFailure: (err) => {
          console.log(err);
          reject(err);
        }
      });
    });
  };

  const getUsername = () => {
    return cognitoUser.getUsername();
  };

  const handleChangePassword = ({ oldPassword, newPassword }) => {
    return new Promise((resolve, reject) => {
      cognitoUser.changePassword(oldPassword, newPassword, (err, result) => {
        if (err) {
          console.log(err.message || JSON.stringify(err));
          alert(err.message || JSON.stringify(err));
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
  };

  const providerValue = useMemo(
    () => ({
      authenticate,
      getSession,
      logout,
      handleNewPassword,
      handleForgotPassword,
      handleConfirmPassword,
      user,
      getUsername,
      handleChangePassword
    }),
    [user]
  );
  return (
    <div>
      <AccountContext.Provider value={providerValue}>
        {props.children}
      </AccountContext.Provider>
    </div>
  );
};

export { Account, AccountContext };
