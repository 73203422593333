/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useReducer } from 'react';

import cleanObjectEmptyValues from '../core/utils/clean-object-empty-values';

const defaultReducer = (state, action) => {
  switch (action.type) {
    case 'reset':
      return { ...action.payload };
    case 'set':
      // eslint-disable-next-line no-case-declarations
      const obj = { ...state, [action.name]: action.value };
      return cleanObjectEmptyValues(obj);
    default:
      return state;
  }
};

const MatchMatrixFilterContext = createContext({});

const MatchMatrixFilter = (props) => {
  const {
    initialParams = { HIDDEN: false, sort: 'TRAFFICLIGHT-ASC' },
    reducer = defaultReducer,
    children
  } = props;

  const [params, dispatch] = useReducer(reducer, initialParams);

  const paramProps = {
    params,

    setParam: (name, value) => {
      if ((params && params[name] && !value) || value) {
        dispatch({ type: 'set', name, value });
      }
    },
    setSortColumn: (sortableKey, sortOrder) => {
      dispatch({ type: 'set', name: 'sort', value: `${sortableKey}-${sortOrder}` });
    },
    handleReset: () => {
      dispatch({ type: 'reset', payload: initialParams });
    }
  };

  return (
    <MatchMatrixFilterContext.Provider value={paramProps}>
      {children}
    </MatchMatrixFilterContext.Provider>
  );
};

export { MatchMatrixFilter, MatchMatrixFilterContext };
