/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

const AuthenticationInput = ({ error, name, ...rest }) => (
  <div className="AuthenticationInput">
    <input name={name} {...rest} />
    <i>{error}</i>
  </div>
);

export default AuthenticationInput;
