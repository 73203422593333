const Texts = {
  GENERAL: {
    COPYRIGHT: '© 2021 Turtle GmbH',
    UPLOAD_FILE: {
      ON_SUCESS: 'File was upload successfully.',
      ON_ERROR: 'File could not be saved, please try again later.'
    }
  },
  PROFILE: {
    NOTIFICATIONS: {
      CHANGES_SAVED: 'The changes were saved successfully.',
      ERROR_SAVING: 'Changes could not be saved, please try again later.'
    }
  },
  SEAFARER_PROFILE: {
    STATUS_CHANGE: {
      LOADING: 'Applying changes...',
      ON_SUCCESS: 'Status was changed successfully',
      ON_ERROR:
        'Some problems ocurred while changing the status, please try again later.'
    },
    VERIFICATION: {
      LOADING: 'Saving changes...',
      ON_SUCCESS: 'Verification status was changed successfully',
      ON_ERROR:
        'Some problems ocurred while changing the verification status, please try again later.'
    },
    COPY_LINK: {
      ON_SUCCESS: 'Link copied to clipboard',
      ON_ERROR: 'Link copy failed'
    }
  },
  SHIPOWNER_PROFILE: {
    STATUS_CHANGE: {
      LOADING: 'Applying changes...',
      ON_SUCCESS: 'Status was changed successfully',
      ON_ERROR:
        'Some problems ocurred while changing the status, please try again later.'
    }
  },
  SEAFARER_CV: {
    ERROR: 'We are having problems to generate the CV, please try later.'
  },
  JOB: {
    NOTIFICATIONS: {
      LOADING: 'Applying changes...',
      JOB_CREATED: 'The job was created successfully.',
      JOB_CREATED_ERROR: 'Some problems ocurred while creating the job.',
      JOB_CLOSED: 'The job was closed successfully.',
      JOB_CLOSED_ERROR: 'Some problems ocurred while closing the job.'
    },
    CONFIRMATIONS: {
      CLOSE_JOB:
        'Please confirm that you want to close this job and all the related information.'
    }
  },
  MATCH_MATRIX: {
    NOTIFICATIONS: {
      EMBARKATION_DATE_CHANGED_SUCCESS:
        'The embarkation date has been updated successfully.',
      EMBARKATION_DATE_CHANGED_ERROR:
        'The embarkation date cannot be updated currently, please try again later.'
    }
  },
  COMPANY_LIKES: {
    NOTIFICATIONS: {
      ERROR_GETTING_COMPANIES:
        'We are having problems to get the companies, please try later.',
      LOADING: 'Applying changes...',
      LIKES_SAVED: 'Changes were saved successfully.',
      LIKES_SAVED_ERROR: 'Changes could not be saved, please try again later.'
    }
  },
  HIRE: {
    NOTIFICATIONS: {
      LOADING: 'Processing request...',
      ACCEPT_SUCCESS: 'You have successfully accepted the job.',
      ACCEPT_ERROR:
        'Unfortunately the job could not be accepted. Please try again later.',
      REJECT_SUCCESS: 'You have successfully rejected the job.',
      REJECT_ERROR:
        'Unfortunately the job could not be rejected. Please try again later.'
    }
  },
  DELETE_SEAFARER: {
    NOTIFICATIONS: {
      LOADING: 'Deleting seafarer...',
      ON_SUCCESS: 'Seafarer successfully deleted.',
      ON_ERROR: 'Process failed. Please try again later..'
    }
  },
  SEARCH: {
    CONFIRMATIONS: {
      DELETE_SEARCH:
        'Please confirm that you want to delete this search and all the related information.',
      SAVE_CHANGES: 'Please confirm that you want to save the changes.'
    }
  },
  SAVED_SEARCHES: {
    INFO: 'Below are your saved candidate searches. Shortly we will be adding notifications to alert you when new candidates are appearing in your search to make it easier for you to stay up to date with new candidates.'
  },
  LOGIN: {
    WAITING:
      'We are preparing your upgraded TURTLE experience. This can take a few minutes, please wait...',
    WRONG_CREDENTIALS:
      'Your email or password is incorrect, please try again or reset your password through Forgot your password.',
    GENERAL_LOGIN_ERROR: 'We could not log you in, please try again later.',
    ACCOUNT_NOT_CONFIRMED:
      'Your email must be confirmed before you can login. Please check your email and click “Confirm Registration” in the last message you received. Also check your spam folder.'
  },
  SETTINGS: {
    SAVING: 'Saving your changes...',
    ERROR_SAVING: 'Changes could not be saved, please try again later.',
    CHANGES_SAVED: 'The changes were saved successfully.'
  },
  HISTORY_LOGS: {
    CR_SENT: 'Contact Request Sent',
    ACCEPT_CR: 'Contact Request Accepted',
    REJECT_CR: 'Contact Request Rejected',
    CANCEL_CR: 'Contact Request Cancelled',
    HIRE_SENT: 'Offer Sent',
    ACCEPT_HIRE: 'Offer Accepted',
    REJECT_HIRE: 'Offer Rejected',
    CANCEL_HIRE: 'Offer Cancelled',
    WRITE_COMMENT: 'Comment',
    VERIFICATION_REQUEST: 'Verification Request Sent',
    REJECT_CANDIDATE: 'Candidate Rejected',
    CANDIDATE_SAVED: 'Candidate Saved',
    CANDIDATE_UNSAVED: 'Candidate Unsaved',
    EXCLUDE_CANDIDATE: 'Candidate Excluded',
    INCLUDE_CANDIDATE: 'Candidate Included',
    CR_EXPIRED: 'Contact Request Expired'
  }
};

export default Texts;
