import React from 'react';

const AuthenticationLayout = ({ children }) => (
  <div className="AuthenticationLayout">
    <div className="AuthenticationCard">
      <span className="TurtleLogo">TURTLE</span>
      {children}
    </div>
  </div>
);

export default AuthenticationLayout;
