/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext } from 'react';
import useMediaQuery from '../core/hooks/useMediaQuery';

const ResponsiveContext = createContext();

const ResponsiveProvider = ({ children }) => {
  const isPageWide = useMediaQuery('(min-width: 800px)');

  return (
    <ResponsiveContext.Provider value={{ isPageWide }}>
      {children}
    </ResponsiveContext.Provider>
  );
};

export { ResponsiveContext, ResponsiveProvider };
