import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';

import './App.scss';
import { Toaster } from 'react-hot-toast';
import history from './core/utils/history';
import { Account } from './contexts/Accounts';
import { ResponsiveProvider } from './contexts/Responsive';
import { ModalProvider } from './contexts/Modals';
import PrivateRoute from './components/PrivateRoute';
import { MatchMatrixFilter } from './contexts/MatchMatrixFilters';
import Modal from './components/Modal';
import Login from './pages/Login';
import Logout from './pages/Logout';
import ForgotPassword from './pages/ForgotPassword';
import NewPassword from './pages/NewPassword';
import ResetPassword from './pages/ResetPassword';
import ConfirmUser from './pages/ConfirmUser';
import RegistrationComplete from './pages/RegistrationComplete';

const App = () => (
  <div className="App">
    <Toaster
      position="top-right"
      containerStyle={{ position: 'sticky' }}
      toastOptions={{
        style: {
          marginRight: '20px',
          fontFamily: 'RedHatDisplay',
          minHeight: '60px'
        }
      }}
    />
    <ResponsiveProvider>
      <Account>
        <ModalProvider>
          <Router history={history}>
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route exact path="/logout" component={Logout} />
              <Route exact path="/new-password" component={NewPassword} />
              <Route exact path="/forgot-password" component={ForgotPassword} />
              <Route exact path="/reset-password" component={ResetPassword} />
              <Route exact path="/confirmUser" component={ConfirmUser} />
              <Route
                exact
                path="/registration-complete"
                component={RegistrationComplete}
              />
              <MatchMatrixFilter>
                <PrivateRoute />
              </MatchMatrixFilter>
            </Switch>
            <Modal />
          </Router>
        </ModalProvider>
      </Account>
    </ResponsiveProvider>
  </div>
);

export default App;
