import React, { useContext, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { AccountContext } from '../contexts/Accounts';
import getAllowedRoutes from '../core/utils/get-allowed-routes';
import PrivateRoutesConfig from '../core/config/PrivateRoutesConfig';
import MapAllowedRoutes from '../core/routes/MapAllowedRoutes';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import Loader from './Loader';

const PrivateRoute = () => {
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [status, setStatus] = useState();
  const [allowedRoutes, setAllowedRoutes] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const { user, getSession } = useContext(AccountContext);
  const LDClient = useLDClient();

  const goToLogin = () => {
    setLoading(false);
    const fullPath = location.pathname + location.search + location.hash;
    const encodedFullPath = encodeURIComponent(fullPath === '/' ? '' : fullPath);
    history.push(`/login${encodedFullPath ? `?redirect=${encodedFullPath}` : ''}`);
  };

  useEffect(() => {
    if (isLoggedIn) {
      const LDContext = {
        kind: 'multi',
        user: {
          key: user.session.idToken.payload.email,
          email: user.session.idToken.payload.email,
          profileType: 'OPERATOR'
        },
        device: {
          key: 'dev-1',
          platform: 'BROWSER'
        },
        application: {
          key: 'operator-portal',
          name: 'Operator Portal',
          version: '1.0.0'
        }
      };
      LDClient.identify(LDContext, null, () => {
        // console.log('feture flags available for new context');
      });
    }
  }, [user?.session, isLoggedIn]);

  useEffect(() => {
    getSession()
      .then(() => {
        setLoading(false);
        setIsLoggedIn(true);
      })
      .catch((error) => {
        setLoading(false);
        goToLogin();
      });
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      setStatus('OPERATOR');
      if (status) {
        setAllowedRoutes(getAllowedRoutes(PrivateRoutesConfig, status));
      }
    }
  }, [isLoggedIn, status]);

  if (loading && !isLoggedIn) {
    return (
      <div style={{ height: '100vh', width: '100vw' }}>
        <Loader size="lg" />
      </div>
    );
  }

  if (allowedRoutes.length) {
    return <MapAllowedRoutes routes={allowedRoutes} basePath="/" status={status} />;
  }
  return null;
};

export default PrivateRoute;
